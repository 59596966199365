import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAccessToken } from "./getAccessToken";

export const baseQuery = fetchBaseQuery({
    timeout: 60000,
    prepareHeaders: (headers) => {
        headers.set("Content-Type", "application/json");
        const authToken = getAccessToken();
        if (authToken && authToken !== "") {
            headers.set("X-Auth-token", authToken);
        }
        return headers;
    }
});