export {
    compareApi,
    useGetCompareOffersQuery,
    useAddOfferToComparisonMutation,
    useUpdateCompareOffersMutation,
    useRemoveOfferFromComparisonMutation,
    useRemoveAllOffersComparisonMutation
} from "./api/compareApi";

export { default as compareSlice } from "./model/compareSlice";
export { selectCompareCount } from "./model/compareSlice";