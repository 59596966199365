import { Vehicle, ImageObject, WithContext } from "schema-dts";
import Car from "@src/interfaces/carDetails.interface";

export const getVehicleCarSchema = (data: Car, pageUrl: string): WithContext<Vehicle> => {
    const driveConfig = data.drive?.toLowerCase().includes("передний")
        ? "FWD"
        : data.drive?.toLowerCase().includes("задний")
          ? "RWD"
          : data.drive?.toLowerCase().includes("полный") ||
              data.drive?.toLowerCase().includes("4wd")
            ? data.transmission?.toLowerCase() === "автомат"
                ? "AWD"
                : "4WD"
            : undefined;

    const currencyCode =
        data.sourceId === 6 || data.sourceId === 7 || data.sourceId === 8
            ? "KZT"
            : data.sourceId === 9
              ? "EUR"
              : data.sourceId === 10
                ? "BYN"
                : "RUB";

    const images: ImageObject[] =
        data.pics?.map((pic) => ({
            "@type": "ImageObject",
            contentUrl: pic.bigUrl || pic.url
        })) || [];

    const itemCondition = data.isDamaged
        ? "https://schema.org/DamagedCondition"
        : "https://schema.org/UsedCondition";

    const availability =
        data.statusId === 5 ? "https://schema.org/SoldOut" : "https://schema.org/InStock";

    return {
        "@context": "https://schema.org",
        "@type": "Vehicle",
        additionalType: "Car",
        url: pageUrl,
        mainEntityOfPage: pageUrl,
        name: data.title || "",
        description: data.description,
        productionDate: data.year?.toString(),
        mileageFromOdometer: {
            "@type": "QuantitativeValue",
            unitCode: "KMT",
            value: Number(data.mileage) || 0
        },
        bodyType: data.body || "",
        fuelType: data.engine || "",
        vehicleTransmission: data.transmission || "",
        driveWheelConfiguration: driveConfig || "",
        vehicleEngine: {
            "@type": "EngineSpecification",
            engineDisplacement: {
                "@type": "QuantitativeValue",
                value: parseFloat(data.engine) || 0,
                unitText: "L"
            },
            enginePower: {
                "@type": "QuantitativeValue",
                value: Number(data.horsePower) || 0,
                unitCode: "HP"
            },
            fuelType: data.engine || ""
        },
        brand: {
            "@type": "Brand",
            name: data.brand || ""
        },
        image: images,
        offers: {
            "@type": "Offer",
            priceCurrency: currencyCode,
            price: data.price || 0,
            itemCondition: itemCondition,
            availability: availability
        },
        color: data.color || ""
    };
};