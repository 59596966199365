import { createSlice } from "@reduxjs/toolkit";
import { compareApi } from "@entities/compare";
import { AppState } from "@app/store/store";
import { logoutUser, setUser } from "@app/store/authSlice";
import {
    loadCompareCountFromLocalStorage,
    saveCompareCountToLocalStorage
} from "@entities/compare/lib";

const initialState = {
    compareCount: 0
};

const compareSlice = createSlice({
    name: "compare",
    initialState,
    reducers: {
        setCompareCount(state, action) {
            state.compareCount = action.payload;
            saveCompareCountToLocalStorage(state.compareCount);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setUser, (state, action) => {
                if (action.payload) {
                    state.compareCount = loadCompareCountFromLocalStorage();
                }
            })
            .addCase(logoutUser, (state) => {
                state.compareCount = 0;
            })
            .addMatcher(compareApi.endpoints.getCompareOffers.matchFulfilled, (state, action) => {
                state.compareCount = action.payload.offers.length;
                saveCompareCountToLocalStorage(state.compareCount);
            })
            .addMatcher(compareApi.endpoints.addOfferToComparison.matchFulfilled, (state) => {
                state.compareCount += 1;
                saveCompareCountToLocalStorage(state.compareCount);
            })
            .addMatcher(compareApi.endpoints.removeOfferFromComparison.matchFulfilled, (state) => {
                state.compareCount -= 1;
                saveCompareCountToLocalStorage(state.compareCount);
            })
            .addMatcher(compareApi.endpoints.removeAllOffersComparison.matchFulfilled, (state) => {
                state.compareCount = 0;
                saveCompareCountToLocalStorage(state.compareCount);
            });
    }
});

export const selectCompareCount = (state: AppState) => state.compare.compareCount;
export default compareSlice;