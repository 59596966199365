import { COMPARE_COUNT_KEY } from "./constants";

export const loadCompareCountFromLocalStorage = (): number => {
    if (typeof window !== "undefined") {
        const savedCount = localStorage.getItem(COMPARE_COUNT_KEY);
        return savedCount ? parseInt(savedCount, 10) : 0;
    }
    return 0;
};

export const saveCompareCountToLocalStorage = (count: number) => {
    if (typeof window !== "undefined") {
        localStorage.setItem(COMPARE_COUNT_KEY, count.toString());
    }
};